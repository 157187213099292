var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-view',{attrs:{"title":_vm.title,"filter":"","create":"","create-title":"Создать аккаунт","url-create":("/user/create?role=" + _vm.queryRole),"url-update":"/user/update/:id","store-module":"user","query-filters":['role'],"headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Активность', value: 'active', width: 1, sortable: false },
        { text: 'Роль', value: 'role', width: 1, sortable: false },
        { text: 'Логин', value: 'login' },
        { text: 'ФИО', value: 'name' },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems},scopedSlots:_vm._u([{key:"title.right",fn:function(){return [_c('v-btn',{staticClass:"ml-auto mr-2",attrs:{"text":"","href":"/download/user_upload_template.xlsx"}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-file ")]),_c('span',[_vm._v("Скачать шаблон")])],1),_c('v-btn',{staticClass:"ml-auto mr-2 white--text",attrs:{"to":"/user/upload","color":_vm.$const.color.primary}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-upload ")]),_c('span',[_vm._v("Загрузить")])],1)]},proxy:true},{key:"filter",fn:function(ref){
    var filter = ref.filter;
return [_c('v-row',[_c('v-col',{attrs:{"lg":"2","sm":"12"}},[_c('v-select',{attrs:{"items":[{
                    text: 'Все',
                    value: null
                  },{
                    text: 'Нет',
                    value: false
                  },{
                    text: 'Да',
                    value: true
                  }],"label":"Активность"},model:{value:(filter.active),callback:function ($$v) {_vm.$set(filter, "active", $$v)},expression:"filter.active"}})],1),_c('v-col',{attrs:{"lg":"5","sm":"12"}},[_c('v-text-field',{attrs:{"autocomplete":"new-email","label":"Логин","type":"text","placeholder":" ","color":"purple darken-2"},model:{value:(filter.login),callback:function ($$v) {_vm.$set(filter, "login", $$v)},expression:"filter.login"}})],1),_c('v-col',{attrs:{"lg":"5","sm":"12"}},[_c('v-text-field',{attrs:{"autocomplete":"new-email","label":"ФИО","type":"text","placeholder":" ","color":"purple darken-2"},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }