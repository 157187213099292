<template>
    <grid-view 
        :title="title"
        filter
        create
        create-title="Создать аккаунт"
        :url-create="`/user/create?role=${queryRole}`"
        url-update="/user/update/:id"
        store-module="user"
        :query-filters="['role']"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Активность', value: 'active', width: 1, sortable: false },
            { text: 'Роль', value: 'role', width: 1, sortable: false },
            { text: 'Логин', value: 'login' },
            { text: 'ФИО', value: 'name' },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
    >
        <!-- Extra buttons in title -->
        <template #title.right>
            <v-btn text href="/download/user_upload_template.xlsx" class="ml-auto mr-2">
                <v-icon left dark>
                    mdi-file
                </v-icon>
                <span>Скачать шаблон</span>
            </v-btn>
            <v-btn to="/user/upload" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-upload
                </v-icon>
                <span>Загрузить</span>
            </v-btn>
        </template>
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="2" sm="12">
                    <v-select
                      v-model="filter.active"
                      :items="[{
                        text: 'Все',
                        value: null
                      },{
                        text: 'Нет',
                        value: false
                      },{
                        text: 'Да',
                        value: true
                      }]"
                      label="Активность"
                    ></v-select>
                </v-col>

                <!-- <v-col lg="6" sm="12">
                    <v-select
                      v-model="filter.role"
                      :items="[{
                        text: 'Все',
                        value: null
                      },
                      ...$store.state.user.roles
                      ]"
                      label="Роль"
                    ></v-select>
                </v-col> -->

                <v-col lg="5" sm="12">
                    <v-text-field 
                        v-model="filter.login"
                        autocomplete="new-email"
                        label="Логин" 
                        type="text" 
                        placeholder=" "
                        color="purple darken-2" 
                    ></v-text-field>
                </v-col>

                <v-col lg="5" sm="12">
                    <v-text-field 
                        v-model="filter.name"
                        autocomplete="new-email"
                        label="ФИО" 
                        type="text" 
                        placeholder=" "
                        color="purple darken-2" 
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'UserIndex',
    components: { GridView }, 
    data() {
        return {}
    },
    computed: {
        queryRole () {
            return !this.$route.query.role || this.$route.query.role === 'undefined' ? '' : this.$route.query.role;
        },
        title () {
            const values = {
                teacher: 'Преподаватели',
                student: 'Учащиеся',
                default: 'Пользователи'
            };
            return values[this.queryRole] || values.default;
        }  
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'active', item.active === true ? 'Да' : 'Нет');
                // Role
                const role_i = _.findIndex(this.$store.state.user.roles, {value: item.role});
                _.set(item, 'role', _.get(this, `$store.state.user.roles.${role_i}.text`, 'Неизвестно'));

                return item;
            })
        } 
    }
}
</script>